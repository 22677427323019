<template>
  <!-- Two-way Data-Binding -->
  <div>
    <div v-show="showToolBar" id="toolbar-container">
    <span class="ql-formats">
      <button @click="undoText" class="custom-btn" id="custom-btn-undo"><i class="fas fa-undo cutom-btn-icon"></i></button>
      <button @click="redoText" class="custom-btn" id="custom-btn-redo"><i class="fas fa-redo cutom-btn-icon"></i></button>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <select class="ql-align"></select>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
      <select class="ql-size"></select>
      <select class="ql-color"></select>
      <select class="ql-background"></select>
      <select class="ql-font"></select>
      <button class="ql-strike"></button>
      <button class="ql-link"></button>
      <button class="ql-code-block"></button>
      <button class="ql-clean"></button>
    </span>
    <!-- <span class="ql-formats">
      <button class="ql-script" value="sub"></button>
      <button class="ql-script" value="super"></button>
      <button class="ql-header" value="1"></button>
      <button class="ql-header" value="2"></button>
      <button class="ql-blockquote"></button>
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-direction" value="rtl"></button>
      <button class="ql-link"></button>
      <button class="ql-image"></button>
      <button class="ql-video"></button>
      <button class="ql-formula"></button>
      <button class="ql-clean"></button>
    </span> -->
  </div>
    <quill-editor
      ref="myQuillEditor"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)"
    />
  <div class="action-btn-group relative">
    <button @click="showToolBar = !showToolBar"  type="button" class="formatingOption mb-2 md:mb-0 text-white  font-medium text-sm px-2 py-px text-center ">
         <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" :fill="showToolBar ? `#1976d2` : `#636161`"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 17v2h14v-2H5zm4.5-4.2h5l.9 2.2h2.1L12.75 4h-1.5L6.5 15h2.1l.9-2.2zM12 5.98L13.87 11h-3.74L12 5.98z"/></svg>
    <span class="fomrat_tooltiptext  bg-gray2 bg-transparent whitespace-nowrap rounded">Formatting Options</span>
    </button>
    <button v-if="showAttach"  @click="openAddFile"  type="button" class="formatingOption mb-2 md:mb-0 text-white  font-medium text-sm px-2 py-px text-center ">
        <AttachmentSvg :height="'28px'" />
    <span class="tooltiptext  bg-gray2 bg-transparent whitespace-nowrap rounded">Attach files</span>
    </button>
    <button  v-if="showAttach"  type="button" class="formatingOption mb-2 md:mb-0 text-white  font-medium text-sm px-2 py-px text-center ">
        <span @click="openAddTag" v-if="showTag">
          <PlustagSvg :height="'25px'"/>
        </span>
    <span class="tooltiptext  bg-gray2 bg-transparent whitespace-nowrap rounded">Add tags</span>
    </button>
   
    <!-- <v-tooltip  v-if="showAttach"  bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" text small @click="openAddFile">
          <v-icon>mdi-attachment</v-icon>
        </v-btn>
      </template>
      <span>Attach files</span>
    </v-tooltip>
     <v-tooltip  v-if="showAttach"  bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" v-if="showTag" text small @click="openAddTag">
          <v-icon size="20">mdi-tag-plus-outline</v-icon>
        </v-btn>
      </template>
      <span>Add tags</span>
    </v-tooltip> -->
  </div>
  </div>
</template>
<script>
import Mention from 'quill-mention'
import { quillEditor } from 'vue-quill-editor'
// import Project from '@/View/Project/api/project.js'
// import MagicUrl from 'quill-magic-url'
import Quill from 'quill'
// Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/mention', Mention)
// var toolbarOptions = [
//   ['bold', 'bold', {'list': 'ordered'}, { 'list': 'bullet' }, 'bold', 'italic', 'underline', { 'align': [] }, {'indent': '-1'}, { 'indent': '+1' }, { 'size': ['small', false, 'large', 'huge'] }, { 'color': [] }, { 'background': [] }, { 'font': [] }, 'strike', 'code-block', 'clean']
// ]
// [
//   ['bold', 'italic', 'underline', 'strike'],
//   ['blockquote', 'code-block'],

//   [{ 'header': 1 }, { 'header': 2 }],
//   [{'list': 'ordered'}, { 'list': 'bullet' }],
//   [{'script': 'sub'}, { 'script': 'super' }],
//   [{'indent': '-1'}, { 'indent': '+1' }],
//   [{ 'direction': 'rtl' }],

//   [{ 'size': ['small', false, 'large', 'huge'] }],
//   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//   [{ 'color': [] }, { 'background': [] }],
//   [{ 'font': [] }],
//   [{ 'align': [] }],
//   ['clean']
// ]
import AttachmentSvg from '@/assets/images/svg/attachmentSvg.vue'
import PlustagSvg from '@/assets/images/svg/plustagSvg.vue'
export default {
  props: ['value', 'showAttach', 'showTag', 'memberList', 'appendValue', 'autoFocus'],
  model: {
    prop: 'value',
    event: 'input'
  },
  components: {
    AttachmentSvg,
    PlustagSvg,
    quillEditor
  },
  data () {
    return {
      requestFrom: 'project',
      showFomrat: false,
      showToolBar: false,
      content: this.value,
      editorOption: {
        placeholder: 'Please type something here...',
        theme: 'snow',
        modules: {
          toolbar: '#toolbar-container',
          // magicUrl: true,
          mention: {
            allowedChars: /^[a-zA-Z0-9_]*$/,
            mentionDenotationChars: ['@'],
            renderItem: (item) => {
              return item.display
            },
            source: (searchTerm, renderList, mentionChar) => {
              let values = this.memberList
              console.log('mentionChar', mentionChar)
              console.log('values', values)
              if (searchTerm.length === 0) {
                renderList(values, searchTerm)
              } else {
                const matches = []
                for (let i = 0; i < values.length; i++) {
                  if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i])
                }
                renderList(matches, searchTerm)
              }
            }
          }
        }
        // Some Quill options...
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.content = this.value
      }
    },
    content: {
      handler () {
        // console.log('check content');
      }
    },
    appendValue: {
      handler () {
        if (this.appendValue !== '') {
          const selection = this.editor.selection.savedRange
          this.editor.insertText(selection.index, this.appendValue)
          let len = this.editor.selection.savedRange.index + this.appendValue.length
          setTimeout(() => {
            this.editor.setSelection(len, 0)
          }, 0)
        }
      }
    }
  },
  methods: {
    onEditorBlur () {
    //   console.log('editor blur!', quill)
    },
    onEditorFocus () {
    //   console.log('editor focus!', quill)
    },
    onEditorReady () {
    //   console.log('editor ready!', quill)
      // this.showToolBar = false
    },
    onEditorChange ({ html }) {
    //   console.log('editor change!', quill, html, text)
      this.content = html
      this.$emit('input', html)
      // let len = quill.selection.savedRange.index + this.appendValue.length
      // console.log('lent', len)
      // setTimeout(() => {
      //   quill.setSelection(len, 0)
      // }, 0)
    },
    openAddFile () {
      this.$emit('onAttachmentClick', true)
    },
    openAddTag () {
      this.$emit('onAddTagClick', true)
    },
    undoText () {
      this.editor.history.undo()
      console.log('call undo')
    },
    redoText () {
      this.editor.history.redo()
      console.log('call undo')
    }
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted () {
    if (this.autoFocus) {
      this.editor.focus()
      this.editor.setSelection(this.value.length, 0)
    }
    if (this.quill !== undefined) {
      setTimeout(() => {
      this.quill.root.blur()
    }, 200);  
    }
  }
}
</script>
<style scoped>
.action-btn-group {
  border-width: 0px 1px 1px 1px;
  border-color: #c7c7c7;
  border-style: solid;
  display: inline-flex;

}
.custom-btn {
  padding-top: 0px!important;
}
.cutom-btn-icon {
  color: #49494e;
  font-size: 13px;
}
.quill-editor {
  min-height: 120px;
  font-size: 16px!important
}
.formatingOption {
  position: relative;
  display: inline-block;
}

.formatingOption .tooltiptext {
  visibility: hidden;
  width: 150px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.formatingOption:hover .tooltiptext {
  visibility: visible;
}
.formatingOption .fomrat_tooltiptext {
  visibility: hidden;
  width: 150px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 130%;
  margin-left: -60px;
}

.formatingOption:hover .fomrat_tooltiptext {
  visibility: visible;
}
</style>
